import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/Layout";
import ProductGrid from "../components/ProductGrid";
import { Row, Col } from "react-bootstrap";

// export const GearPageTemplate = ({ title, titleSEO, logo, content }) => <ProductGrid />;

const GearPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.titleSEO} />
      <Row className="d-flex flex-row justify-content-center align-items-center mx-0" style={{ height: "90vh" }}>
        <Col className="text-center">
          <h1>Coming soon</h1>
        </Col>
      </Row>
      {/* <ProductGrid /> */}
      {/* <GearPageTemplate title={frontmatter.title} /> */}
    </Layout>
  );
};

export default GearPage;

export const pageQuery = graphql`
  query GearPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "gear-page" } }) {
      frontmatter {
        title
        titleSEO
      }
    }
  }
`;
